declare module '@mui/system/createTheme/shape' {
	interface Shape {
		borderRadiusXs: number | string;
		borderRadiusSm: number | string;
		borderRadiusMd: number | string;
		borderRadiusLg: number | string;
	}
}

const shape = {
	borderRadius: 7,
	borderRadiusXs: 2,
	borderRadiusSm: 12,
	borderRadiusMd: 16,
	borderRadiusLg: 22,
};

export default shape;
