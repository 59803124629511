import { alpha, Theme } from '@mui/material';
import { pxToRem } from '../../utils/common';
import { grey } from '@mui/material/colors';
import { darken, lighten } from 'polished';

export default function Button(theme: Theme) {
	return {
		MuiButton: {
			variants: [
				{
					props: { variant: 'contained' },
					style: {
						color: theme.palette.common.black,
					},
				},
				{
					props: { variant: 'inversed' },
					style:
						theme?.palette?.mode === 'light'
							? {
									backgroundColor: grey['600'],
									color: theme.palette.common.white,
									'&:hover, &:focus': {
										backgroundColor: grey['900'],
										color: theme.palette.common.white,
									},
									'&.Mui-disabled': {
										backgroundColor: grey['700'],
										color: darken(0.2, theme.palette.common.white),
									},
									'.MuiButton-startIcon': {
										'.MuiCircularProgress-root': {
											color: grey['500'],
										},
									},
							  }
							: {
									backgroundColor: theme.palette.common.white,
									color: theme.palette.common.black,
									'&:hover, &:focus': {
										backgroundColor: grey['200'],
										color: theme.palette.common.black,
									},
							  },
				},
				{
					props: { variant: 'overlay', color: 'primary' },
					style:
						theme?.palette?.mode === 'light'
							? {
									backgroundColor: theme.palette.primary.dark,
									color: theme.palette.common.white,
									'&:hover, &:focus': {
										backgroundColor: darken(0.1, theme.palette.primary.dark),
										color: theme.palette.common.white,
									},
							  }
							: {
									backgroundColor: theme.palette.primary.light,
									color: theme.palette.common.white,
									'&:hover, &:focus': {
										backgroundColor: lighten(0.1, theme.palette.primary.dark),
										color: theme.palette.common.white,
									},
							  },
				},
				{
					props: { variant: 'overlay', color: 'secondary' },
					style:
						theme?.palette?.mode === 'light'
							? {
									backgroundColor: theme.palette.secondary.dark,
									color: theme.palette.common.white,
									'&:hover, &:focus': {
										backgroundColor: darken(0.2, theme.palette.secondary.dark),
										color: theme.palette.common.white,
									},
							  }
							: {
									backgroundColor: theme.palette.secondary.light,
									color: theme.palette.common.white,
									'&:hover, &:focus': {
										backgroundColor: lighten(0.2, theme.palette.secondary.dark),
										color: theme.palette.common.white,
									},
							  },
				},
				{
					props: { variant: 'inverse' },
					style: {
						backgroundColor: theme.palette.common.white,
						color: theme.palette.common.black,
						'&:hover, &:focus': {
							backgroundColor: theme.palette.common.white,
							color: alpha(theme.palette.common.black, 0.8),
						},
					},
				},
			],
			styleOverrides: {
				root: {
					textTransform: 'none',
					paddingLeft: pxToRem(20),
					paddingRight: pxToRem(20),
					paddingTop: pxToRem(12),
					paddingBottom: pxToRem(12),
					borderRadius: 25,
					fontWeight: theme.typography.fontWeightBold,

					'&:hover': {
						boxShadow: 'none',
					},

					'.MuiButton-startIcon': {
						'.MuiCircularProgress-root': {
							height: 20,
							width: 20,
						},
					},
				},
				sizeLarge: {
					height: 48,
				},
				containedInherit: {
					color: theme.palette.grey[800],
					boxShadow: theme.customShadows.z8,
					'&:hover': {
						backgroundColor: theme.palette.grey[400],
					},
				},
				containedPrimary: {
					boxShadow: theme.customShadows.primary,
				},
				containedSecondary: {
					boxShadow: theme.customShadows.secondary,
				},
				outlinedInherit: {
					border: `1px solid ${theme.palette.grey[500_32]}`,
					'&:hover': {
						backgroundColor: theme.palette.action.hover,
					},
				},
				textInherit: {
					'&:hover': {
						backgroundColor: theme.palette.action.hover,
					},
				},
			},
		},
	};
}

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		inversed: true;
		overlay: true;
		white: true;
		light: true;
		blue: true;
	}
}
