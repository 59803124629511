import { Deployment } from '../models/api/deployment';
import { PrizeSteps } from '../models/api/prize-session';
import { ValidStep, ValidSteps } from '../components/Prizes';

export const kebabCase = (str: string) =>
	str
		.replace(/([a-z])([A-Z])/g, '$1-$2')
		.replace(/[\s_]+/g, '-')
		.toLowerCase();

export function isValidJSON(string: string) {
	return /^[\],:{}\s]*$/.test(
		/* eslint-disable */
		string
			.replace(/\\["\\\/bfnrtu]/g, '@')
			.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
			.replace(/(?:^|:|,)(?:\s*\[)+/g, '')
		/* eslint-enable */
	);
}

// export const getTranslations = (deployment: Deployment) => {
// 	const translations = {};
//
// 	deployment?.discover?.languages?.forEach(({ language, translation }) => {
// 		translations[language.code] = { translation };
// 	});
//
// 	return translations;
// };

export const findValidStep = (steps: ValidSteps) => {
	const stepNames = Object.keys(steps) as ValidStep[];
	const falseIndex = stepNames.findIndex((stepName) => !steps[stepName]);
	if (falseIndex === -1) {
		return 'prize-claim';
	} else {
		return stepNames.slice(0, falseIndex).reverse()[0];
	}
};

export function isValidStringify(value: string) {
	return (typeof value === 'object' && value !== null) || Array.isArray(value);
}

export const pxToRem = (value: number) => `${value / 16}rem`;

// capitalised the first letter of a sentence, useful for Formik form errors
export const capitalise = (text: string) => {
	return `${text.charAt(0).toUpperCase()}${text.slice(1)}`;
};

export const camelCase = (str: string) => `${str.replace(/-([a-z])/g, (g) => g[1].toUpperCase())}`;

export const formatTag = (value: string) => {
	return `${value}`
		.split(' ')
		.join('')
		.toLowerCase()
		.replace(/[^\w\s]/gi, '')
		.slice(0, 20);
};

export const isValidLongitude = (longitude?: number) =>
	typeof longitude === 'number' && longitude >= -180 && longitude <= 180;

export const isValidLatitude = (latitude?: number) =>
	typeof latitude === 'number' && latitude >= -90 && latitude <= 90;

export const COUNTRIES = [
	'AF',
	'AX',
	'AL',
	'DZ',
	'AS',
	'AD',
	'AO',
	'AI',
	'AQ',
	'AG',
	'AR',
	'AM',
	'AW',
	'AU',
	'AT',
	'AZ',
	'BS',
	'BH',
	'BD',
	'BB',
	'BY',
	'BE',
	'BQ',
	'BZ',
	'BJ',
	'BM',
	'BT',
	'BO',
	'BA',
	'BW',
	'BV',
	'BR',
	'IO',
	'BN',
	'BG',
	'BF',
	'BI',
	'KH',
	'CM',
	'CA',
	'CV',
	'KY',
	'CF',
	'TD',
	'CL',
	'CN',
	'CX',
	'CC',
	'CO',
	'KM',
	'CG',
	'CD',
	'CK',
	'CR',
	'CI',
	'HR',
	'CU',
	'CW',
	'CY',
	'CZ',
	'DK',
	'DJ',
	'DM',
	'DO',
	'EC',
	'EG',
	'SV',
	'GQ',
	'ER',
	'EE',
	'ET',
	'FK',
	'FO',
	'FJ',
	'FI',
	'FR',
	'GF',
	'PF',
	'TF',
	'GA',
	'GM',
	'GE',
	'DE',
	'GH',
	'GI',
	'GR',
	'GL',
	'GD',
	'GP',
	'GU',
	'GT',
	'GG',
	'GN',
	'GW',
	'GY',
	'HT',
	'HM',
	'VA',
	'HN',
	'HK',
	'HU',
	'IS',
	'IN',
	'ID',
	'IR',
	'IQ',
	'IE',
	'IM',
	'IL',
	'IT',
	'JM',
	'JP',
	'JE',
	'JO',
	'KZ',
	'KE',
	'KI',
	'KP',
	'KR',
	'KW',
	'KG',
	'LA',
	'LV',
	'LB',
	'LS',
	'LR',
	'LY',
	'LI',
	'LT',
	'LU',
	'MO',
	'MK',
	'MG',
	'MW',
	'MY',
	'MV',
	'ML',
	'MT',
	'MH',
	'MQ',
	'MR',
	'MU',
	'YT',
	'MX',
	'FM',
	'MD',
	'MC',
	'MN',
	'MS',
	'MA',
	'MZ',
	'MM',
	'NA',
	'NR',
	'NP',
	'NL',
	'NC',
	'NZ',
	'NI',
	'NE',
	'NG',
	'NU',
	'NF',
	'MP',
	'NO',
	'OM',
	'PK',
	'PW',
	'PS',
	'PA',
	'PG',
	'PY',
	'PE',
	'PH',
	'PN',
	'PL',
	'PT',
	'PR',
	'QA',
	'RE',
	'RO',
	'RU',
	'RW',
	'SH',
	'KN',
	'LC',
	'PM',
	'VC',
	'WS',
	'SM',
	'ST',
	'SA',
	'SN',
	'SC',
	'SL',
	'SG',
	'SK',
	'SI',
	'SB',
	'SO',
	'SS',
	'SX',
	'ZA',
	'GS',
	'ES',
	'LK',
	'SD',
	'SR',
	'SJ',
	'SZ',
	'SE',
	'CH',
	'SY',
	'TW',
	'TJ',
	'TZ',
	'TH',
	'TL',
	'TG',
	'TK',
	'TO',
	'TT',
	'TN',
	'TR',
	'TM',
	'TC',
	'TV',
	'UG',
	'UA',
	'AE',
	'GB',
	'US',
	'UM',
	'UY',
	'UZ',
	'VU',
	'VE',
	'VN',
	'VG',
	'VI',
	'WF',
	'EH',
	'YE',
	'ZM',
	'ZW',
	'RS',
	'ME',
	'XK',
];
