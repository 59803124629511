import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locales/en/translations.json';
import ja from '../locales/ja/translations.json';
import ko from '../locales/ko/translations.json';
import ar from '../locales/ar/translations.json';

i18next
	.use(initReactI18next) // passes i18n down to react-i18next
	.init(
		{
			lng: 'en',
			fallbackLng: ['en', 'ja', 'ko', 'ar'],
			ns: ['translation'],
			defaultNS: 'translation',
			react: { useSuspense: false },
			interpolation: { escapeValue: false },
			resources: {
				en: { translation: en },
				ja: { translation: ja },
				ko: { translation: ko },
				ar: { translation: ar },
			},
			supportedLngs: ['en', 'ja', 'ko', 'ar'],
		},
		// We must provide a function as second parameter, otherwise i18next errors
		(err, t) => {
			if (err) return console.log(err);
		}
	);

export default i18next;
