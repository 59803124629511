import { isValidJSON, isValidStringify } from './common';
import config from '../config.json';

export function getHostname(name: string) {
	const prefix = `pmi-diw-scratch-card`;

	if (config.nodeEnv === 'development') {
		return `${prefix}_local_${name}`;
	}

	return `${prefix}_${name}`;
}

// Cookies and localStorage
export function bakeLocalStorage(name: string, value: any) {
	if (typeof window !== 'undefined') {
		localStorage.setItem(
			getHostname(name),
			isValidStringify(value) ? JSON.stringify(value) : value
		);
	}
}

export function readLocalStorage<Data = unknown>(name: string): Data | null | undefined {
	if (typeof window !== 'undefined') {
		let value = localStorage.getItem(getHostname(name));
		return typeof value === 'string' && isValidJSON(value) ? JSON.parse(value) : value;
	}

	return null;
}

export function deleteLocalStorage(name: string) {
	if (typeof window !== 'undefined') {
		return localStorage.removeItem(getHostname(name));
	}
}

export function bakeCookie(
	name: string,
	value: any,
	date?: Date | string | null,
	format: boolean = true
) {
	if (typeof window !== 'undefined') {
		const expiry = !!date ? `expires=${date};` : '';

		document.cookie = `${format ? getHostname(name) : name}=${JSON.stringify(
			value
		)};${expiry}path=/`;
	}
}

export function readCookie(name: string) {
	if (typeof window !== 'undefined') {
		let value: string | undefined = '; ' + document.cookie;
		const parts: string[] = value.split('; ' + getHostname(name) + '=');
		value = parts.length === 2 ? parts?.pop()?.split(';')?.shift() : undefined;

		return value ? (isValidJSON(value) ? JSON.parse(value) : value) : undefined;
	}

	return null;
}

export function deleteCookie(name: string, format = true) {
	if (typeof window !== 'undefined') {
		document.cookie = `${
			format ? getHostname(name) : name
		}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/`;
	}
}
