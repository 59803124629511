import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Typography, Button } from '@mui/material';

export const ErrorBoundary: React.FC<FallbackProps & { error: any }> = ({
  error,
  resetErrorBoundary,
}) => {
  const status = error?.response?.status;
  const data = error?.response?.data;
  const errorName = error?.response?.data?.errorName || error?.name;

  if (status === 500) {
    return <p>You're offline!</p>;
  }

  if (status === 404) {
    return (
      <>
        <Typography variant='h1'>404</Typography>
        <Typography>Not found!</Typography>
        <Button onClick={() => (window.location.href = '/')}>Go home</Button>
      </>
    );
  }

  // the response json is automatically parsed to
  // `error.data`, you also have access to the status
  return (
    <div>
      <Typography variant='h1'>{status}</Typography>
      <Typography variant='h2'>{data?.errorName}</Typography>
      <Typography>
        Go ahead and email {error?.data?.hrEmail} if you feel like this is a
        mistake.
      </Typography>
      <Typography>
        Please note that cookies are currently disabled on your mobile device,
        in order to participate in the promotion, please change your settings to
        enable Cookies and try again. Thank you
      </Typography>
      <Button onClick={resetErrorBoundary}>Try again</Button>
    </div>
  );
};

export default ErrorBoundary;
