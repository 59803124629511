import { useContext } from 'react';
import { SnackbarContext, defaultDuration, defaultPosition, defaultSeverity } from './Snackbar';
import { AlertColor } from '@mui/lab/Alert';
import { SnackbarOrigin } from '@mui/material/Snackbar';

export type openSnackbar = (
	text: string,
	severity?: AlertColor | null,
	duration?: number | null,
	position?: SnackbarOrigin | null
) => void;

export type closeSnackbar = () => void;

// Custom hook to trigger the snackbar on function components
export const useSnackbar = ({
	duration: globalDuration = defaultDuration,
	position: globalPosition = defaultPosition,
} = {}) => {
	const { openSnackbar, closeSnackbar } = useContext(SnackbarContext);

	function open(
		text = '',
		severity?: AlertColor | null,
		duration?: number | null,
		position?: SnackbarOrigin | null
	) {
		openSnackbar?.(
			text,
			severity || defaultSeverity,
			duration || globalDuration,
			position || globalPosition
		);
	}

	// Returns methods in hooks array way
	return [open, closeSnackbar];
};
