import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CircularProgress, CssBaseline, useTheme } from '@mui/material';
import SnackbarProvider from './components/Snackbar';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundary as _ErrorBoundary } from './components/ErrorBoundary';
import { library } from '@fortawesome/fontawesome-svg-core';
import { Global } from '@emotion/react';
import Theme from './components/Theme';
import { rootRoutes } from './routes';
import { useRoutes } from './utils/contexts';
import config from './config.json';

import {
	faEye as fasEye,
	faArrowLeft as fasArrowLeft,
	faCloudUpload as fasCloudUpload,
} from '@fortawesome/pro-solid-svg-icons';
import {
	faPieChart as fadPieChart,
	faMountainSun as fadMountainSun,
	faServer as fadServer,
	faTablet as fadTablet,
	faSignsPost as fadSignsPost,
	faQrcode as fadQrcode,
	faImages as fadImages,
	faBarsStaggered as fadBarsStaggered,
} from '@fortawesome/pro-duotone-svg-icons';
import globalStyles from './globalStyles';
import RoutesProvider from './components/Routes';
import { I18nextProvider } from 'react-i18next';
import i18n from './utils/i18n';

library.add(
	fasEye,
	fasArrowLeft,
	fadPieChart,
	fadMountainSun,
	fadServer,
	fadTablet,
	fadSignsPost,
	fadQrcode,
	fadImages,
	fasCloudUpload,
	fadBarsStaggered
);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			useErrorBoundary: (error: any) =>
				!(error?.response?.status >= 400 && error?.response?.status < 500),
			refetchOnWindowFocus: false,
			networkMode: config.nodeEnv === 'development' ? 'always' : undefined,
		},
		mutations: {
			// Offline support
			networkMode: config.nodeEnv === 'development' ? 'always' : undefined,
		},
	},
});

const Fallback = (
	<div
		style={{
			height: '100vh',
			width: '100%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		}}
	>
		<CircularProgress />
	</div>
);

const AppWrapper = () => {
	const theme = useTheme();
	const { router } = useRoutes();

	// TODO: Use real precache from RDM in the future if it's an experience

	return (
		<Suspense fallback={Fallback}>
			<Global styles={globalStyles(theme)} />
			{router}
		</Suspense>
	);
};

const App = () => {
	return (
		<ErrorBoundary
			FallbackComponent={_ErrorBoundary}
			onReset={() => {
				// reset the state of your app so the error doesn't happen again
			}}
		>
			<BrowserRouter>
				<Theme>
					<I18nextProvider i18n={i18n}>
						<QueryClientProvider client={queryClient}>
							<SnackbarProvider>
								<RoutesProvider routes={rootRoutes}>
									<CssBaseline />
									<AppWrapper />
								</RoutesProvider>
							</SnackbarProvider>
						</QueryClientProvider>
					</I18nextProvider>
				</Theme>
			</BrowserRouter>
		</ErrorBoundary>
	);
};

export default App;
