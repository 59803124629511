import { alpha } from '@mui/material/styles';
import { darken, lighten } from 'polished';

// ----------------------------------------------------------------------

export type ColorSchema = 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

function createGradient(color1: string, color2: string) {
	return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

interface GradientsPaletteOptions {
	primary: string;
	info: string;
	success: string;
	warning: string;
	error: string;
}

declare module '@mui/material/styles/createPalette' {
	interface TypeBackground {
		neutral: string;
	}
	interface SimplePaletteColorOptions {
		lighter: string;
		darker: string;
	}
	interface PaletteColor {
		lighter: string;
		darker: string;
	}
	interface Palette {
		tertiary: PaletteColor;
		gradients: GradientsPaletteOptions;
	}
	interface PaletteOptions {
		tertiary?: PaletteColor;
		gradients?: GradientsPaletteOptions;
	}
	interface CommonColors {
		grey: string;
	}
}

declare module '@mui/material' {
	interface Color {
		0: string;
		500_8: string;
		500_12: string;
		500_16: string;
		500_24: string;
		500_32: string;
		500_48: string;
		500_56: string;
		500_80: string;
	}
}

// SETUP COLORS
const PRIMARY = {
	lighter: '#F0F2F7',
	light: '#C3CBE6',
	main: '#BB0000',
	dark: darken(0.05, '#BB0000'),
	darker: darken(0.3, '#BB0000'),
};

const SECONDARY = {
	lighter: lighten(0.2, '#CFE5E6'),
	light: '#CFE5E6',
	main: '#46BABE',
	dark: darken(0.05, '#46BABE'),
	darker: darken(0.3, '#46BABE'),
};

const TERTIARY = {
	lighter: lighten(0.2, '#CFE6DF'),
	light: '#CFE6DF',
	main: '#6EC0A9',
	dark: darken(0.05, '#6EC0A9'),
	darker: darken(0.3, '#6EC0A9'),
};

const INFO = {
	lighter: '#D0F2FF',
	light: '#74CAFF',
	main: '#1890FF',
	dark: '#0C53B7',
	darker: '#04297A',
};

const SUCCESS = {
	lighter: '#E9FCD4',
	light: '#AAF27F',
	main: '#54D62C',
	dark: '#229A16',
	darker: '#08660D',
};

const WARNING = {
	lighter: lighten(0.2, '#FFE16A'),
	light: '#FFE16A',
	main: '#FFC107',
	dark: darken(0.05, '#FFC107'),
	darker: darken(0.3, '#FFC107'),
};

const ERROR = {
	lighter: lighten(0.1, '#E6CFD4'),
	light: '#E6CFD4',
	main: '#BE4662',
	dark: darken(0.05, '#BE4662'),
	darker: darken(0.3, '#BE4662'),
};

const GREY = {
	100: '#F9FAFB',
	200: '#F4F6F8',
	300: '#DFE3E8',
	400: '#D6D6D6',
	500: '#919EAB',
	600: '#637381',
	700: '#454F5B',
	800: '#212B36',
	900: '#161C24',
	500_8: alpha('#919EAB', 0.08),
	500_12: alpha('#919EAB', 0.12),
	500_16: alpha('#919EAB', 0.16),
	500_24: alpha('#919EAB', 0.24),
	500_32: alpha('#919EAB', 0.32),
	500_48: alpha('#919EAB', 0.48),
	500_56: alpha('#919EAB', 0.56),
	500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
	primary: createGradient(PRIMARY.light, PRIMARY.main),
	info: createGradient(INFO.light, INFO.main),
	success: createGradient(SUCCESS.light, SUCCESS.main),
	warning: createGradient(WARNING.light, WARNING.main),
	error: createGradient(ERROR.light, ERROR.main),
};

const COMMON = {
	common: { black: '#000', white: '#fff', grey: '#333333' },
	primary: { ...PRIMARY, contrastText: '#fff' },
	secondary: { ...SECONDARY, contrastText: '#fff' },
	tertiary: { ...TERTIARY, contrastText: '#fff' },
	info: { ...INFO, contrastText: '#fff' },
	success: { ...SUCCESS, contrastText: GREY[800] },
	warning: { ...WARNING, contrastText: GREY[800] },
	error: { ...ERROR, contrastText: '#fff' },
	grey: GREY,
	gradients: GRADIENTS,
	divider: GREY[500_24],
	action: {
		hover: GREY[500_8],
		selected: GREY[500_16],
		disabled: GREY[500_80],
		disabledBackground: GREY[500_24],
		focus: GREY[500_80],
		hoverOpacity: 0.08,
		disabledOpacity: 0.48,
	},
};

const palette = {
	light: {
		...COMMON,
		text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
		background: { paper: '#fff', default: PRIMARY.lighter, neutral: GREY[200] },
		action: { active: GREY[600], ...COMMON.action },
	},
	dark: {
		...COMMON,
		text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
		background: { paper: PRIMARY.main, default: PRIMARY.darker, neutral: PRIMARY.dark },
		action: { active: GREY[500], ...COMMON.action },
	},
};

export default palette;
